import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const checkoutSuccess = () => {
  if (typeof window !== 'undefined') {
    const paramString = window.location.search;
    const successParam = new URLSearchParams(paramString);

    if (successParam.get("checkout") === 'success') {
      return true;
    }

    return false;
  }
}

const SuccessModal = ({ data }) => {
  const [show, setShow] = useState(checkoutSuccess);
  const handleClose = () => setShow(false);

  return (
    <Modal onHide={handleClose} show={show} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton={handleClose}>
        <Modal.Title>We appreciate you!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Thanks for choosing The Little Layup. If you need any help please don't hesitate to contact us <a href="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D">here</a>.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        toastIcon: file(relativePath: { eq: "icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 20, height: 20) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <SuccessModal data={data} />}
  />
);
