import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2);

  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  });

  return numberFormat.format(price);
}

const description = (name, metadata) => {
  if (typeof metadata === 'undefined') return

  if (name === 'Glossy A4 Landscape') {
    return metadata['glossy_a4_landscape_description']
  } else if (name === 'Glossy A4 Portrait') {
    return metadata['glossy_a4_portrait_description']
  } else if (name === 'PPM Manual') {
    return metadata['manual_description']
  } else {
    return metadata[`${name.toLowerCase()}_description`];
  }
}

const handleMetadata = (name, metadata) => {
  if (name.includes("Glossy")) {
    return metadata.glossy_features
  } else if (name.includes("PPM")) {
    return metadata.manual_features
  } else {
    return metadata.features
  }
}

class SkuCard extends Component {
  async redirectToCheckout(e, sku, quantity = 1) {
    e.preventDefault();

    const { error } = await this.props.stripe.redirectToCheckout({
      items: [{ sku, quantity }],
      successUrl: `${window.location.origin}/shop/?checkout=success`,
      cancelUrl: `${window.location.origin}/shop/?checkout=cancel`,
    });

    if (error) {
      console.warn('Error:', error);
    }
  }

  render() {
    const {
      id,
      image,
      price,
      currency,
    } = this.props.sku;

    const {
      name,
    } = this.props.sku.attributes;

    const {
      metadata,
    } = this.props.sku.product;

    return (
      <StaticQuery
        query={graphql`
          query {
            cardShadow: file(relativePath: { eq: "card-shadow.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 288, height: 666) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            checkIcon: file(relativePath: { eq: "check-icon.png" }) {
              childImageSharp {
                fixed(quality: 100, width: 14, height: 11) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <Card className="sku-card">
            <Card.Img variant="top" src={image} />
            <Card.Body>
              <Card.Title>{name}</Card.Title>
              <Card.Text>{description(name, metadata)}</Card.Text>
              <ListGroup variant="flush">
                {
                  metadata
                  ? JSON.parse(handleMetadata(name, metadata)).map((v, k) => {
                    return (
                      <ListGroup.Item key={k}>
                        <Row>
                          <Col xs={1} sm={1}>
                            <Img
                              fixed={data.checkIcon.childImageSharp.fixed}
                              alt="Check Icon"
                              className="check-icon"
                            />
                          </Col>
                          <Col xs={11} sm={11}>{v}</Col>
                        </Row>
                      </ListGroup.Item>
                    )
                  })
                  : null
                }
              </ListGroup>
              <button className="btn btn-outline-primary btn-block" onClick={e => this.redirectToCheckout(e, id)}>
                {"Buy now – " + formatPrice(price, currency)}
              </button>
            </Card.Body>
            <Img
              alt="Card Shadow"
              fixed={data.cardShadow.childImageSharp.fixed}
              className="card-shadow-img"
            />
          </Card>
        )}
      />
    )
  }
}

export default SkuCard;
