import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import SkuCard from './sku-card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Skus extends Component {
  state = {
    stripe: null,
  }

  componentDidMount() {
    const stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
    this.setState({ stripe });
  }

  render() {
    const productName = this.props.productName;
    let skus = null;

    return (
      <StaticQuery
        query={graphql`
          query SkusForProduct {
            pitchTemplateSkus: allStripeSku(filter: {product: { name: {eq: "Pitch Templates"}}}, sort: { fields: price, order: DESC }) {
              edges {
                node {
                  id
                  currency
                  price
                  image
                  attributes {
                    name
                  }
                  product {
                    name
                    metadata {
                      sleek_description
                      light_description
                      space_description
                      lifestyle_description
                      flexy_description
                      glossy_a4_landscape_description
                      glossy_a4_portrait_description
                      features
                      glossy_features
                    }
                  }
                }
              }
            }
            ppmTemplateSkus: allStripeSku(filter: {product: { name: {eq: "PPM Templates"}}}) {
              edges {
                node {
                  id
                  currency
                  price
                  image
                  attributes {
                    name
                  }
                  product {
                    name
                    metadata {
                      manual_description
                      manual_features
                    }
                  }
                }
              }
            }
            productionTemplateSkus: allStripeSku(filter: {product: { name: {eq: "Production Templates"}}}) {
              edges {
                node {
                  id
                  currency
                  price
                  attributes {
                    name
                  }
                  product {
                    name
                  }
                }
              }
            }
          }
        `}
        render={({
          pitchTemplateSkus,
          ppmTemplateSkus,
          productionTemplateSkus,
        }) => {
          if (productName === 'Pitch Templates') {
            skus = pitchTemplateSkus
          } else if (productName === 'PPM Templates') {
            skus = ppmTemplateSkus
          } else {
            skus = productionTemplateSkus
          }

          return (
            <Row>
              {skus.edges.map(({ node: sku }) => {
                return (
                  <Col xs={12} sm={12} md={12} lg={6} key={sku.id}>
                    <SkuCard
                      sku={sku}
                      stripe={this.state.stripe}
                    />
                  </Col>
                )
              })}
            </Row>
          )
        }}
      />
    )
  }
}

export default Skus;
