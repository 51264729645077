import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Divider from '../components/divider';
import Skus from '../components/Products/skus';
import SuccessModal from '../components/success-modal';

const Shop = () => (
  <Layout>
    <SEO title="Shop" />
    <Container className="shop-title">
      <Row className="justify-content-md-center">
        <Col md={10}>
          <h1 className="text-center">Shop</h1>
        </Col>
      </Row>
    </Container>
    <Divider />
    <div className="border-container">
      <Container className="shop-category-tabs">
        <Row>
          <Col sm={12}>
            <Tabs defaultActiveKey="pitchTemplates" className="justify-content-center">
              <Tab eventKey="pitchTemplates" title="Pitch Templates">
                <Skus productName="Pitch Templates" />
              </Tab>
              <Tab title="PPM Templates" eventKey="PPM Templates">
                <Skus productName="PPM Templates" />
              </Tab>
              <Tab title="Production Templates (Coming Soon)" eventKey="disabled" disabled>
                <Skus productName="Production Templates" />
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <SuccessModal />
      </Container>
    </div>
    <Divider />
    <div className="border-container">
      <div className="shop-shout">
        <Row className="justify-content-md-center">
          <Col md={10}>
            <h2 className="text-center">We update our templates regularly, so watch this space!</h2>
          </Col>
        </Row>
      </div>
    </div>
  </Layout>
);

export default Shop;
